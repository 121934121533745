import styles from './index.module.scss'
import Telegram from 'Components/Icons/telegtam';
import { isAndroid, isIOS } from "react-device-detect";
import { env } from 'env';

function Link(): string {
  if (isAndroid || isIOS) {
    return "tg://t.me/" + env.REACT_APP_BOT_USERNAME
  } else {
    return "https://t.me/" + env.REACT_APP_BOT_USERNAME
  }
}

export function BotLink() {

  return (
  // <a className={styles.botLink} href={env.REACT_APP_BOT_URL} target="_blank">
  <a className={styles.botLink} href={Link()} target="_blank">
    <Telegram />
  </a>
  )
}

export default BotLink