function NumberIcon() {
  return (
    <svg height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0H2C0.89543 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.89543 13.1046 0 12 0Z" fill="#FFA16F" />
      <path d="M9.02703 5.892L8.72703 7.358H10.357V8.658H8.48403L7.99703 11.225H6.60403L7.10403 8.66H5.87403L5.39403 11.225H4.03603L4.50403 8.66H3.00403V7.36H4.75403L5.05403 5.894H3.45403V4.572H5.28003L5.76703 2H7.15103L6.66403 4.572H7.91503L8.40403 2H9.76603L9.27403 4.572H10.79V5.892H9.02703ZM6.12103 7.358H7.35903L7.65903 5.892H6.41803L6.12103 7.358Z" fill="white" />
    </svg>
  );
}

export default NumberIcon;

