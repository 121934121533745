import { useEffect, useState } from 'react';
import styles from './index.module.scss'
import { useAppSelector, useAppDispatch } from 'store/hooks';
import React from 'react';
import { selectUserInput, setUserInput } from 'store/userinput/userinputSlice';
import { getCities, selectCities, setCities } from 'store/cities/citiesSlice';
// import { selectCoordinates, setCoordinates } from 'store/coordinates/coordinatesSlice';
import { selectCity, selectCityName } from 'store/city/citySlice';
import SearchIcon from 'Components/Icons/search';
import { selectAddressInfo, getIPInfo, setAddressInfo } from 'store/addressinfo/addressinfoSlice';
import { IPGeoInfo, ISP, City, Country, Continent, Reputation} from 'types/index';
import StringIcon from 'Components/Icons/json/string';
import BoolIcon from 'Components/Icons/json/bool';
import ObjectIcon from 'Components/Icons/json/object';
import ArrayIcon from 'Components/Icons/json/array';
import NumberIcon from 'Components/Icons/json/number';
import ErrorIcon from 'Components/Icons/json/error';
// import SimpleBar from 'simplebar-react';
// import 'simplebar-react/dist/simplebar.min.css';
import { Scrollbars } from 'react-custom-scrollbars-2';

declare interface structureString {
  key: string;
  value: string;
  valueType: string;
  indent: number;
}

export function Info() {
  const mocInfo: IPGeoInfo = {
    IP: "127.0.0.1",
    Name: "FFFFF",
    Domain: "GGGGggggggggggggg gggggggggggggggggggg GGGGGG",
    City: {
      GeoNameID: 555,
      MetroCode: 123,
      PostalCode: "66666",
      Names: {"RU": "Russia"},
    },
    Continent: {
      Code: "fg",
      GeoNameID: 555,
      Names: {"RU": "Russia"},
    },
    Country: {
      IsInEuropeanUnion: false,
      GeoNameID: 6252001,
      Flag: "🇺🇸",
      IsoCode: "US",
      Names: {
        "de": "Vereinigte Staaten",
        "en": "United States",
        "es": "Estados Unidos",
        "fr": "États Unis",
        "ja": "アメリカ",
        "pt-BR": "EUA",
        "ru": "США",
        "zh-CN": "美国",
      }
    },
    Reputation: {
      Blacklists: [
        "bla11111111111111111111111111111111111111111111111111111111111111111111111111",
        "bla22222222222222222222222222222222222222222222222222222222222222222222222222",
        "bla33333333333333333333333333333333333333333333333333333333333333333333333333",
        "bla44444444444444444444444444444444444444444444444444444444444444444444444444",

      ]
    }
  }
  
  const addressInfo = useAppSelector(selectAddressInfo);
  function isObjEmpty (obj: any) {
    return Object.values(obj).length === 0 && obj.constructor === Object;
  }
  // Преобразует JSON в массив строк
  function structureCreator(object: any, name: string, indent: number): structureString[] {
    var structureToShow: structureString[] = [];
    addressInfo && Object.keys(object).map((key: string, index: number) => {
      let value: any
      //При появлении новых структур или подструктур добавить в этот свич
      console.log("object", object)
      console.log("name", name)
      console.log("indent", indent)
      console.log("key", key)
      console.log("value", object[key])
      switch (key) {
        // case 'ISP':
        //   if (object[key as keyof ISP].values) {
        //     value = object[key as keyof ISP];
        //   }
        //   break;
        // case 'City':
        //   if (object[key as keyof City].valuse) {
        //     value = object[key as keyof City];
        //   }
        //   break;
        // case 'Country':
        //   if (object[key as keyof Country].valuse) {
        //     value = object[key as keyof Country];
        //   }
        //   break;
        // case 'Continent':
        //   if (object[key as keyof Continent].valuse) {
        //     value = object[key as keyof Continent];
        //   }
        //   break;
        // case 'Reputation':
        //   if (object[key as keyof Reputation].valuse) {
        //     value = object[key as keyof Reputation];
        //   }
        //   break;

        // case 'Type':
        //   if (object[key as keyof Type].valuse) {
        //     value = object[key as keyof Type];
        //   }
        //   break;
        case 'Latitude':
          if (object[key as keyof IPGeoInfo] !== 0) {
            value = object[key as keyof IPGeoInfo];
          }
          break;
        case 'Longitude':
          if (object[key as keyof IPGeoInfo] !== 0) {
            value = object[key as keyof IPGeoInfo];
          }
          break;
        case 'AccuracyRadius':
          if (object[key as keyof IPGeoInfo] !== 0) {
            value = object[key as keyof IPGeoInfo];
          }
          break;
        case 'TimeZone':
          if (object[key as keyof IPGeoInfo].length !== 0) {
            value = object[key as keyof IPGeoInfo];
          }
          break;
        case 'ConnectionType':
          if (object[key as keyof IPGeoInfo].length !== 0) {
            value = object[key as keyof IPGeoInfo];
          }
          break;
        default:
          // switch (key) {
          //   case 'Latitude':
          //     if (object[key as keyof IPGeoInfo] !== 0) {
          //       value = object[key as keyof IPGeoInfo];
          //     }
          //     break;
          //   case 'Longitude':
          //     if (object[key as keyof IPGeoInfo] !== 0) {
          //       value = object[key as keyof IPGeoInfo];
          //     }
          //     break;
          //   case 'AccuracyRadius':
          //     if (object[key as keyof IPGeoInfo] !== 0) {
          //       value = object[key as keyof IPGeoInfo];
          //     }
          //     break;
          //   case 'TimeZone':
          //     if (object[key as keyof IPGeoInfo].length !== 0) {
          //       value = object[key as keyof IPGeoInfo];
          //     }
          //     break;
          //   case 'ConnectionType':
          //     if (object[key as keyof IPGeoInfo].length !== 0) {
          //       value = object[key as keyof IPGeoInfo];
          //     }
          //     break;
          //   default:
              value = object[key as keyof IPGeoInfo];
              break;
          // }
          // break;
      }

      // console.log("indent", indent, "key", key, "value", value, "type", typeof value)
      // console.log("Array", Array.isArray(value), "value", value)
      // if (name === 'Error') {
      //   structureToShow.push({key: key, value: value.toString(), valueType: 'error', indent: indent});
      //   return
      // }
      if (Array.isArray(value) && value !== null) {
        value.length
        structureToShow.push({key: key, value: 'Array['+value.length+']', valueType: 'array', indent: indent});
        var tmpstructureToShow: structureString[];
        tmpstructureToShow = structureCreator(value, key, indent+1)
        // indent=indent-1
        tmpstructureToShow.map((val: structureString)=>structureToShow.push(val))
        return
      }
      if (typeof value === 'object' && value !== null) {
        structureToShow.push({key: key, value: 'Object', valueType: typeof value, indent: indent});
        var tmpstructureToShow: structureString[];
        tmpstructureToShow = structureCreator(value, key, indent+1)
        // indent=indent-1
        tmpstructureToShow.map((val: structureString)=>structureToShow.push(val))
        return
      }
      if (typeof value !== 'object') {
        if (typeof value === 'boolean') {
          structureToShow.push({key: key, value: value.toString(), valueType: typeof value, indent: indent});
        } else {
          structureToShow.push({key: key, value: value, valueType: typeof value, indent: indent});
        }
      }
    })
    return structureToShow;
  }

  // Выбор иконки в зависимости от типа значения
  function typeIcon(valueType: string): JSX.Element {
    switch (valueType) {
      case 'string':
        return <StringIcon />
        break;
      case 'boolean':
        return <BoolIcon />
        break;
      case 'object':
        return <ObjectIcon />
        break;
      case 'number':
        return <NumberIcon />
        break;
      case 'array':
        return <ArrayIcon />
        break;
      // case 'error':
      //   return <ErrorIcon />
      //   break;
      default:
        return <></>
        break;
    }
  }
  function keyRename(key: string): string {
    switch (key) {
      case 'AutonomousSystemNumber':
        return 'ASNumber';
      case 'AutonomousSystemOrganization':
        return 'ASOrg';
      case 'Organization':
        return 'Org';
      case 'AccuracyRadius':
        return 'Radius';
      default:
        return key;
    }
  }

  // Убирает градиент внизу страницы
  const [isVisible, setIsVisible] = useState(true);
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const gradient = document.getElementById(
      'gradient',
    ) as HTMLDivElement | null;
    var H: number = 0;
    if (gradient != null) {
      H = gradient.offsetHeight;
    }
    const bottom = (e.target as HTMLElement).scrollHeight - (e.target as HTMLElement).scrollTop <= (e.target as HTMLElement).clientHeight + H;
    if (bottom) { 
      isVisible && setIsVisible(false);
        // console.log("bottom")
    } else {
      setIsVisible(true);
    }
  }

  return (
    // <div className={styles.infoWrapper}>

    <Scrollbars
      className={styles.scroller}
      // style={window.innerWidth <= 600 ? {height: '43vh'} : {height: 10000}}
      style={{height: 10000}}
      // renderThumbHorizontal={ <div style={{ display: 'none'}}/>}
      // autoHeight 
      // autoHeightMin='100%'
      autoHide
      hideTracksWhenNotNeeded
      // universal
    >
      <div className={styles.infoContainer}>
      {/* <div className={styles.infoContainer} onScroll={handleScroll}> */}
        {
          addressInfo && structureCreator(addressInfo, '', 0).map((str: structureString, index: number) => {
            if(str.value != undefined){

              // {console.log(str.value)}
              // {console.log("**************************")}
              // addressInfo && structureCreator(mocInfo, '', 0).map((str: structureString, index: number) => {
              return (
                // <div key={index} className={styles.stringContainer}>
                //   <div style={{marginLeft: `calc(${str.indent} * 24px + ${str.indent} * 7px)`}}>
                //     {str.key === 'Error' ?  <ErrorIcon /> : typeIcon(str.valueType)}
                //   </div>
                //   <div className={styles.stringKeyContainer}>
                //     <div className={str.key === 'Error' ? styles.stringKeyError : styles.stringKey}>
                //       {str.key}
                //     </div>
                //     <div className={styles.punctuation}>:</div>
                //   </div>
                //   <div className={styles.stringValueContainer}>
                //     {str.valueType !== 'boolean' && str.valueType !== 'number' && <div className={styles.punctuation}>"</div> }
                //     <div className={
                //       str.value === 'Object' || String(str.value).includes('Array[') ? styles.punctuation : 
                //       str.valueType === 'boolean' ? styles.stringBoolValue : 
                //       str.valueType === 'number' ? styles.stringNumberValue : styles.stringValue}
                //     >
                //       {str.value}
                //     </div>
                //     {str.valueType !== 'boolean' && str.valueType !== 'number' && <div className={styles.punctuation}>"</div> }
                //     <div className={styles.punctuation}>,</div>
                //   </div>
                // </div>
                <div key={index} style={{marginLeft: `calc(${str.indent} * 21px + ${str.indent} * 7px)`}} className={styles.stringContainer}>
                  <span>
                    <span className={styles.floatLeft}>
                      <span className={styles.Icon}>
                        {str.key === 'Error' ?  <ErrorIcon /> : typeIcon(str.valueType)}
                      </span>
                      {+str.key !== +str.key && <span className={str.key === 'Error' ? styles.stringKeyError : styles.stringKey}>
                        {keyRename(str.key)}
                        <span className={styles.punctuation}>:</span>
                      </span> }
                      {/* <span className={str.key === 'Error' ? styles.stringKeyError : styles.stringKey}>
                        {keyRename(str.key)}
                        <span className={styles.punctuation}>:</span>
                      </span> */}
                    </span>
                    <span className={styles.punctuation}>
                      {str.valueType !== 'boolean' && str.valueType !== 'number' ? "\"" : ""}
                      <span
                        className={
                        str.value === 'Object' || String(str.value).includes('Array[') ? styles.punctuation : 
                        str.valueType === 'boolean' ? styles.stringBoolValue : 
                        str.valueType === 'number' ? styles.stringNumberValue : styles.stringValue}
                      >
                        {str.value}
                      </span>
                      {str.valueType !== 'boolean' && str.valueType !== 'number' ? "\"" : ""}
                      <span className={styles.punctuation}>,</span>
                    </span>
                  </span>
                </div>
              )
            }
          })
        }
        {/* {isVisible && <div className={styles.gradient} id={"gradient"}>&nbsp;</div> } */}
    </div>
    </Scrollbars>
    // </div>
  )
}

export default Info