import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';

export interface State {
  value: string;
}
const initialState = {value: ''};

export const userinputSlice = createSlice({
  name: 'userinput',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserInput: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setUserInput } = userinputSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserInput = (state: RootState) => state.userinput.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default userinputSlice.reducer;
