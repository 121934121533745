import { useEffect, useState } from 'react';
import styles from './index.module.scss'
import { useAppSelector, useAppDispatch } from 'store/hooks';
import React from 'react';
import { selectUserInput, setUserInput } from 'store/userinput/userinputSlice';
import { getCities, selectCities, setCities } from 'store/cities/citiesSlice';
// import { selectCoordinates, setCoordinates } from 'store/coordinates/coordinatesSlice';
import { selectCity, selectCityName } from 'store/city/citySlice';
import SearchIcon from 'Components/Icons/search';
import { selectAddressInfo, getIPInfo, setAddressInfo } from 'store/addressinfo/addressinfoSlice';
import { IPGeoInfo, ISP, City, Country, Continent, Reputation} from 'types/index';
import StringIcon from 'Components/Icons/json/string';
import BoolIcon from 'Components/Icons/json/bool';
import ObjectIcon from 'Components/Icons/json/object';
import ArrayIcon from 'Components/Icons/json/array';
import NumberIcon from 'Components/Icons/json/number';
import ErrorIcon from 'Components/Icons/json/error';

declare interface structureString {
  key: string;
  value: string;
  valueType: string;
  indent: number;
}

export function Info() {
  const mocInfo: IPGeoInfo = {
    IP: "127.0.0.1",
    Name: "FFFFF",
    Domain: "GGGGggggggggggggg gggggggggggggggggggg GGGGGG",
    City: {
      GeoNameID: 555,
      MetroCode: 123,
      PostalCode: "66666",
      Names: {"RU": "Russia"},
    },
    Continent: {
      Code: "fg",
      GeoNameID: 555,
      Names: {"RU": "Russia"},
    },
    Country: {
      IsInEuropeanUnion: false,
      GeoNameID: 6252001,
      Flag: "🇺🇸",
      IsoCode: "US",
      Names: {
        "de": "Vereinigte Staaten",
        "en": "United States",
        "es": "Estados Unidos",
        "fr": "États Unis",
        "ja": "アメリカ",
        "pt-BR": "EUA",
        "ru": "США",
        "zh-CN": "美国",
      }
    },
    Reputation: {
      Blacklists: [
        "bla11111111111111111111111111111111111111111111111111111111111111111111111111",
        "bla22222222222222222222222222222222222222222222222222222222222222222222222222",
        "bla33333333333333333333333333333333333333333333333333333333333333333333333333",
        "bla44444444444444444444444444444444444444444444444444444444444444444444444444",

      ]
    }
  }
  
  const addressInfo = useAppSelector(selectAddressInfo);

  // Преобразует JSON в массив строк
  function structureCreator(object: any, name: string, indent: number): structureString[] {
    var structureToShow: structureString[] = [];
    addressInfo && Object.keys(object).map((key: string, index: number) => {
      let value: any
      //При появлении новых структур или подструктур добавить в этот свич
      // console.log("name", name)
      switch (name) {
        case 'ISP':
          value = object[key as keyof ISP];
          break;
        case 'City':
          value = object[key as keyof City];
          break;
        case 'Country':
          value = object[key as keyof Country];
          break;
        case 'Continent':
          value = object[key as keyof Continent];
          break;
        case 'Reputation':
          value = object[key as keyof Reputation];
          break;
        default:
          value = object[key as keyof IPGeoInfo];
          break;
      }

      // console.log("indent", indent, "key", key, "value", value, "type", typeof value)
      // console.log("Array", Array.isArray(value), "value", value)
      // if (name === 'Error') {
      //   structureToShow.push({key: key, value: value.toString(), valueType: 'error', indent: indent});
      //   return
      // }
      if (Array.isArray(value) && value !== null) {
        value.length
        structureToShow.push({key: key, value: 'Array['+value.length+']', valueType: 'array', indent: indent});
        var tmpstructureToShow: structureString[];
        tmpstructureToShow = structureCreator(value, key, indent+1)
        // indent=indent-1
        tmpstructureToShow.map((val: structureString)=>structureToShow.push(val))
        return
      }
      if (typeof value === 'object' && value !== null) {
        structureToShow.push({key: key, value: 'Object', valueType: typeof value, indent: indent});
        var tmpstructureToShow: structureString[];
        tmpstructureToShow = structureCreator(value, key, indent+1)
        // indent=indent-1
        tmpstructureToShow.map((val: structureString)=>structureToShow.push(val))
        return
      }
      if (typeof value !== 'object') {
        if (typeof value === 'boolean') {
          structureToShow.push({key: key, value: value.toString(), valueType: typeof value, indent: indent});
        } else {
          structureToShow.push({key: key, value: value, valueType: typeof value, indent: indent});
        }
      }
    })
    return structureToShow;
  }

  // Выбор иконки в зависимости от типа значения
  function typeIcon(valueType: string): JSX.Element {
    switch (valueType) {
      case 'string':
        return <StringIcon />
        break;
      case 'boolean':
        return <BoolIcon />
        break;
      case 'object':
        return <ObjectIcon />
        break;
      case 'number':
        return <NumberIcon />
        break;
      case 'array':
        return <ArrayIcon />
        break;
      // case 'error':
      //   return <ErrorIcon />
      //   break;
      default:
        return <></>
        break;
    }
  }
  function keyRename(key: string): string {
    switch (key) {
      case 'AutonomousSystemNumber':
        return 'ASNumber';
      case 'AutonomousSystemOrganization':
        return 'ASOrg';
      case 'Organization':
        return 'Org';
      default:
        return key;
    }
  }

  // Убирает градиент внизу страницы
  const [isVisible, setIsVisible] = useState(true);
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const gradient = document.getElementById(
      'gradient',
    ) as HTMLDivElement | null;
    var H: number = 0;
    if (gradient != null) {
      H = gradient.offsetHeight;
    }
    const bottom = (e.target as HTMLElement).scrollHeight - (e.target as HTMLElement).scrollTop <= (e.target as HTMLElement).clientHeight + H;
    if (bottom) { 
      isVisible && setIsVisible(false);
        // console.log("bottom")
    } else {
      setIsVisible(true);
    }
  }

  return (
    <div className={styles.infoContainer} onScroll={handleScroll}>
      {
        addressInfo && structureCreator(addressInfo, '', 0).map((str: structureString, index: number) => {
          // addressInfo && structureCreator(mocInfo, '', 0).map((str: structureString, index: number) => {
          return (
            // <div key={index} className={styles.stringContainer}>
            //   <div style={{marginLeft: `calc(${str.indent} * 24px + ${str.indent} * 7px)`}}>
            //     {str.key === 'Error' ?  <ErrorIcon /> : typeIcon(str.valueType)}
            //   </div>
            //   <div className={styles.stringKeyContainer}>
            //     <div className={str.key === 'Error' ? styles.stringKeyError : styles.stringKey}>
            //       {str.key}
            //     </div>
            //     <div className={styles.punctuation}>:</div>
            //   </div>
            //   <div className={styles.stringValueContainer}>
            //     {str.valueType !== 'boolean' && str.valueType !== 'number' && <div className={styles.punctuation}>"</div> }
            //     <div className={
            //       str.value === 'Object' || String(str.value).includes('Array[') ? styles.punctuation : 
            //       str.valueType === 'boolean' ? styles.stringBoolValue : 
            //       str.valueType === 'number' ? styles.stringNumberValue : styles.stringValue}
            //     >
            //       {str.value}
            //     </div>
            //     {str.valueType !== 'boolean' && str.valueType !== 'number' && <div className={styles.punctuation}>"</div> }
            //     <div className={styles.punctuation}>,</div>
            //   </div>
            // </div>
            <div key={index} style={{marginLeft: `calc(${str.indent} * 24px + ${str.indent} * 7px)`}} className={styles.stringContainer}>
              <span>
                <span className={styles.floatLeft}>
                  <span className={styles.Icon}>
                    {str.key === 'Error' ?  <ErrorIcon /> : typeIcon(str.valueType)}
                  </span>
                  <span className={str.key === 'Error' ? styles.stringKeyError : styles.stringKey}>
                    {keyRename(str.key)}
                    <span className={styles.punctuation}>:</span>
                  </span>
                </span>
                <span className={styles.punctuation}>
                  {str.valueType !== 'boolean' && str.valueType !== 'number' ? "\"" : ""}
                  <span
                    className={
                    str.value === 'Object' || String(str.value).includes('Array[') ? styles.punctuation : 
                    str.valueType === 'boolean' ? styles.stringBoolValue : 
                    str.valueType === 'number' ? styles.stringNumberValue : styles.stringValue}
                  >
                    {str.value}
                  </span>
                  {str.valueType !== 'boolean' && str.valueType !== 'number' ? "\"" : ""}
                  <span className={styles.punctuation}>,</span>
                </span>
              </span>
            </div>
          //   <div key={index} className={styles.stringContainer}>
          //   <div style={{marginLeft: `calc(${str.indent} * 24px + ${str.indent} * 7px)`}}>
          //     {str.key === 'Error' ?  <ErrorIcon /> : typeIcon(str.valueType)}
          //   </div>
          //   <div className={styles.stringKeyContainer}>
          //     <div className={str.key === 'Error' ? styles.stringKeyError : styles.stringKey}>
          //       {str.key}
          //     </div>
          //     <div className={styles.punctuation}>:</div>
          //   </div>
          //   <div className={styles.stringValueContainer}>
          //     {str.valueType !== 'boolean' && str.valueType !== 'number' && <div className={styles.punctuation}>"</div> }
          //     <div className={
          //       str.value === 'Object' || String(str.value).includes('Array[') ? styles.punctuation : 
          //       str.valueType === 'boolean' ? styles.stringBoolValue : 
          //       str.valueType === 'number' ? styles.stringNumberValue : styles.stringValue}
          //     >
          //       {str.value}
          //     </div>
          //     {str.valueType !== 'boolean' && str.valueType !== 'number' && <div className={styles.punctuation}>"</div> }
          //     <div className={styles.punctuation}>,</div>
          //   </div>
          // </div>
          )
        })
      }
      {/* {isVisible && <div className={styles.gradient} id={"gradient"}>&nbsp;</div> } */}
    </div>
  )
}

export default Info