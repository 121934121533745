import styles from './index.module.scss'
import WeatherCard from 'Components/Main/WeatherCard';
import Input from './Input';
import Info from './Info';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectWeatherArray } from 'store/weatherarray/weatherarraySlice';
import { selectUserInput } from 'store/userinput/userinputSlice';
import { getWeather } from 'store/weatherarray/weatherarraySlice';
import { selectCities } from 'store/cities/citiesSlice';
// import { selectCoordinates } from 'store/coordinates/coordinatesSlice';
import { getCityByPosition, selectCity } from 'store/city/citySlice';
// import { selectAddressInfo, getIPInfo, setAddressInfo } from 'store/addressinfo/addressinfoSlice';
import { selectAddressInfo, selectAddressInfoStatus, getIPInfo } from 'store/addressinfo/addressinfoSlice';
import { useEffect, startTransition } from 'react';
export function Card() {

  const addressInfo = useAppSelector(selectAddressInfo);
  const addressInfoStatus = useAppSelector(selectAddressInfoStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (addressInfo === undefined && addressInfoStatus === 'notset') {
      startTransition(() => {
        dispatch(getIPInfo(""));
      });

      console.log("VVVVVVVVV", addressInfo)
    }
  },[]);
  
  return (
    <main className={styles.main}>
      <Input />
      {addressInfo !== undefined && <Info />}
      {/* {addressInfo !== undefined ? <Info /> : <Loading />} */}
    </main>
  )
}

export default Card