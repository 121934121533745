import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
// import { fetchCount } from './counterAPI';

export const themes = {
  dark: 'dark',
  light: 'light',
}

export interface State {
  value: string;
}
const initialState = createInitialState();

function createInitialState():State {
  //Get from local storage
  const theme = `${window?.localStorage?.getItem('theme')}`
  if (Object.values(themes).includes(theme)) return { value: theme}
  //Get browser mode and use it
  const lightMedia = window.matchMedia('(prefers-color-scheme: light)')
  const darkMedia = window.matchMedia('(prefers-color-scheme: dark)')
  if (lightMedia.matches) {
    localStorage.setItem('theme', 'light')
    return { value: 'light'}
  } else if (darkMedia.matches) {
    localStorage.setItem('theme', 'dark')
    return { value: 'dark'}
  }
  // Else use dark mode
  return { value: 'dark'}
}
export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    themeChange: (state) => {
      state.value === 'light' ? state.value = 'dark' : state.value = 'light';
      localStorage.setItem('theme', state.value);
    },
    themeSet: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
      localStorage.setItem('theme', action.payload);
    },
  },
});

export const { themeChange, themeSet } = themeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTheme = (state: RootState) => state.theme.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default themeSlice.reducer;
