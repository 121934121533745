function ErrorIcon() {
  return (
<svg height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group_741" data-name="Group 741" transform="translate(-12091 699)">
    <rect id="Rectangle_386" data-name="Rectangle 386" fill="#dc8484" width="14" height="14" rx="2" transform="translate(12091 -699)" />
    <g id="Group_740" data-name="Group 740" transform="translate(1)">
      <g id="Ellipse_157" data-name="Ellipse 157" fill="none" stroke="#fff" transform="translate(12093 -696)">
        <circle stroke="none" cx="4" cy="4" r="4" />
        <circle fill="none" cx="4" cy="4" r="3.5" />
      </g>
      <path id="Line_21" data-name="Line 21" fill="none" stroke="#fff" transform="translate(12093.363 -695.636)" d="M7.273 0L0 7.273" />
    </g>
  </g>
</svg>
  );
}

export default ErrorIcon;





