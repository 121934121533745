import styles from './index.module.scss'
import Card from 'Components/Main/Card';
import Map from 'Components/Main/Map';
import Header from 'Components/Main/Header';
import Weather from 'Components/Main/WeatherCard';
import { selectAddressInfo, selectAddressInfoStatus, getIPInfo } from 'store/addressinfo/addressinfoSlice';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useEffect, startTransition } from 'react';
import { Suspense } from 'react';

export function Main() {

  // const weatherArray = useAppSelector(selectWeatherArray);
  const addressInfo = useAppSelector(selectAddressInfo);


  return (
    <main className={styles.main}>
    <Suspense fallback={<div className={styles.headerLoader}>*</div>}>
      <Header />
    </Suspense>
      <Map />

    <Suspense fallback={<div></div>}>
      <Card />
    </Suspense>
      {/* {address  Info !== undefined ? <Card />  : <></>} */}
      {/* {addressInfo && <Weather />} */}
    </main>
  )
}

export default Main