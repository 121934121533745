import { ILocation } from "api/types";
import config from "api/config";

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export interface State {
  value:  ILocation | undefined;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: State = {
  value: {},
  status: 'idle',
};

export const getCityByPosition = createAsyncThunk(
  'citiesbypos/getPosts',
  async (coordinates: Array<number>) => {
    const url = new URL("https://api.openweathermap.org/geo/1.0/reverse");
    url.search = new URLSearchParams({
      limit: "1",
      lat: coordinates[0].toString(),
      lon: coordinates[1].toString(),
      appid: config.API_KEY,
    }).toString();
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const cities = await response.json();
        return cities[0];
      }
      return {};
    } catch {
      return {};
    }
  }
)

export const citySlice = createSlice({
  name: 'city',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCity: (state, action: PayloadAction<ILocation>) => {
      state.value = action.payload;
    },
  },
  extraReducers: builder => {
    builder
    .addCase(getCityByPosition.pending, (state) => {
      state.status = 'loading';
      console.log('loading')
    })
    .addCase(getCityByPosition.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload;

    })
    .addCase(getCityByPosition.rejected, (state) => {
      console.log('failed')
      state.status = 'failed';
    });
  }
});

export const { setCity } = citySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCity = (state: RootState) => state.city.value;
export const selectCityName = (state: RootState) => {
  if (state.city.value) {
    if (state.city.value.local_names) {
      if (state.city.value.local_names.ru) {
        return state.city.value.local_names.ru+', '+state.city.value.country
      }
      if (state.city.value.local_names.en) {
        return state.city.value.local_names.en+', '+state.city.value.country
      }
    }
    if (state.city.value.name) {
      return state.city.value.name+', '+state.city.value.country
    }
  }
  return ''
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default citySlice.reducer;
