// import Flag from 'react-world-flags';
import React from 'react';
import styles from './index.module.scss';
import ClearIcon from 'Components/Icons/clear';
import Flag from 'react-flagkit';
import { useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectUserInput, setUserInput } from 'store/userinput/userinputSlice';
import { IPGeoInfo }  from 'types/index';
import { getIPInfo, setAddressInfo } from 'store/addressinfo/addressinfoSlice';
import { useTranslation } from 'i18nano';

export function Input() {

  const dispatch = useAppDispatch();
  const t = useTranslation();
  const userInput = useAppSelector(selectUserInput);
  const addressInfo = useAppSelector(state => state.addressinfo.value);
  const searchInput = useRef<HTMLInputElement>(null);

  const handleKeywordKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) =>{
    if( e.key == 'Enter' ){
      getIPFromAPI();
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserInput(e.currentTarget.value));
  };

  // console.log("SEARCH", t('search'))
  // Telegram.WebApp.MainButton.setParams({text: t('search')}) //text: t('search'))
  // Telegram.WebApp.MainButton.setText(t('search'))
  
  useEffect(() => {
    if (userInput) {
      Telegram.WebApp.MainButton.setText(t('search'))
      Telegram.WebApp.MainButton.isVisible = true
      Telegram.WebApp.MainButton.isActive = true
      Telegram.WebApp.MainButton.onClick(getIPFromAPI);
    }
    if (userInput === '') {
      Telegram.WebApp.MainButton.hide();
    }
    return () => {
      Telegram.WebApp.MainButton.offClick(getIPFromAPI);
      // Telegram.WebApp.MainButton.hide();
    };
  }, [userInput]);

  const clearInput = () => {
    dispatch(setUserInput(''));
    searchInput!.current!.focus();
  }

  const getIPFromAPI = () => {
    // Значение не изменено
    if (addressInfo && userInput === addressInfo.IP) {
      return
    }
    // Проверка валидности ip адреса
    const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
    // Если проверка не пройдена - ошибка
    if (!regexExp.test(userInput)) {
      const err: IPGeoInfo = {Error: t('ip_error')}
      dispatch(setAddressInfo(err));
      return
    }
    //Иначе запись в стейт
    dispatch(getIPInfo(userInput))
  }

  useEffect(() => {
    if (addressInfo && addressInfo.IP) {
      dispatch(setUserInput(addressInfo.IP));
      searchInput!.current!.focus();
    } else {
      dispatch(setUserInput(""));
      searchInput!.current!.focus();
    }
    searchInput!.current!.focus();
  }, [ addressInfo ])

  const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  return (
    <div className={styles.inputContainer}>
      <form action="" onSubmit={formSubmit} className={styles.mainForm}>
        <input
          ref={searchInput}
          type='text'
          name='search'
          placeholder={t('inputText')}
          className={styles.maininput}
          onInput={handleInput}
          onKeyDown={handleKeywordKeyPress}
          value={userInput}
          autoFocus
        />
      </form>
      {addressInfo && addressInfo.Country && addressInfo.Country.IsoCode && <Flag country={addressInfo.Country.IsoCode} className={styles.Flag} />}
      <button className={styles.clear} onClick={clearInput}>
        <ClearIcon />
      </button>
    </div>
  )
}

export default Input