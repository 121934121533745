import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { themeChange, selectTheme } from 'store/theme/themeSlice';
// import IconButton from '@mui/material/IconButton';
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
import LightIcon from 'Components/Icons/lightTheme';
import DarkIcon from 'Components/Icons/darkTheme';

function ThemeMode() {
  const theme = useAppSelector(selectTheme);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.documentElement.dataset.theme = theme;
    localStorage.setItem('theme', theme);
  }, [ theme ]);

  const changeTheme = () => {
    dispatch(themeChange());
  }

  return (
    <button className={styles.theme} onClick={changeTheme}>
      {theme === 'dark' ? <LightIcon /> : <DarkIcon /> }
    </button>

    
    // <IconButton sx={{ ml: 1 }} onClick={()=>dispatch(themeChange())} color="inherit">
    //   {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    //   {/* {theme === 'dark' ? <Brightness7Icon className={styles}/> : <Brightness4Icon />} */}
    // </IconButton>
  )
}


export default ThemeMode