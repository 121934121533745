
import Main from 'Components/Main';
import styles from './index.module.scss';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import WebApp from 'Components/WebApp';
import { selectLang, langSet } from 'store/i18n/langSlice';
import { useTranslationChange } from 'i18nano';
import { TranslationProvider } from 'i18nano';
import { Suspense } from 'react';

export const translations = {
  // Используя динамический импорт
  // при добавлении нового языка добавить в массив разрешенных в store

  'by': async () => import('i18n/be.json'),
  'de': async () => import('i18n/de.json'),
  'gb': async () => import('i18n/en.json'),
  'es': async () => import('i18n/es.json'),
  'fr': async () => import('i18n/fr.json'),
  'it': async () => import('i18n/it.json'),
  'kr': async () => import('i18n/ko.json'),
  'my': async () => import('i18n/ms.json'),
  'nl': async () => import('i18n/nl.json'),
  'pl': async () => import('i18n/pl.json'),
  'pt': async () => import('i18n/pt.json'),
  'ru': async () => import('i18n/ru.json'),
  'tr': async () => import('i18n/tr.json'),
  'ua': async () => import('i18n/uk.json'),
  'cn': async () => import('i18n/cn.json'),

};

function App() {

  const lang = useAppSelector(selectLang);
  const dispatch = useAppDispatch();
  const translation = useTranslationChange();
  translation.preload('gb');

  if (Telegram.WebApp.initData !== ""
   && Telegram.WebApp.initDataUnsafe.user 
   && Telegram.WebApp.initDataUnsafe.user.language_code) {
    // console.log('LANG', Telegram.WebApp.initDataUnsafe.user.language_code)
    if (Telegram.WebApp.initDataUnsafe.user.language_code in translations) {
      dispatch(langSet(Telegram.WebApp.initDataUnsafe.user.language_code));
    } else {
      // dispatch(langSet('us'));
      dispatch(langSet('gb'));
    }
    // console.log("$$$$$$$$$$$$$$$$$$$$$$")
    // console.log(window.location.pathname)
    // console.log("$$$$$$$$$$$$$$$$$$$$$$")
    if (window.location.pathname !== "/botip") {
      Telegram.WebApp.expand();
    }
  }

  return (
    <div className={styles.app}>
      <TranslationProvider translations={translations} language={lang}>
        {
          Telegram.WebApp.initData === "" ?
          <Main />
          :
          <WebApp />
        }
      </TranslationProvider>
    </div>
  );
}

export default App;
