
declare global {
  interface Window {
    env: any
  }
}

type EnvType = {
  REACT_APP_API_BASE_URL: string,
  REACT_APP_BOT_USERNAME: string,
}

export const env: EnvType = { ...process.env, ...window.env }


