import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import config from "../../api/config";
import { IPGeoInfo } from 'types/index';
import { env } from 'env'

export interface State {
  value:  IPGeoInfo | undefined;
  status: 'idle' | 'loading' | 'failed' | 'notset';
}

// const initialState = createInitialState();

const initialState: State = {
  value: undefined,
  status: 'notset',
};
const IPv4 = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
const IPv6 = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/g;

export const getIPInfo = createAsyncThunk(
  //action type string
  'addressinfo/getInfo',
  async (ip: string) => {
    // var myURL: string = "https://dev.api.ipinfo.rini.moscow/my-ip"
    console.log
    var myURL: string = env.REACT_APP_API_BASE_URL + "/my-ip"
    // Если проверка не пройдена - ошибка
    // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
    // console.log(window.location.pathname.substring(1))
    // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
    if (IPv4.test(window.location.pathname.substring(1)) || IPv6.test(window.location.pathname.substring(1))) {
      myURL = env.REACT_APP_API_BASE_URL + "/" + window.location.pathname.substring(1);
    }
    if (ip !== "") {
      myURL = env.REACT_APP_API_BASE_URL + "/" + ip;
    }
    if (window.location.pathname === "/botip") {
      myURL = env.REACT_APP_API_BASE_URL + "/botip";
      const url = new URL(myURL);
      try {
        const response = await fetch(url, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(Telegram.WebApp.initDataUnsafe.user),
              });
        console.log(response.status)
        if (response.status === 200 || response.status === 429) {
            Telegram.WebApp.close();
        }
        return undefined;
      } catch {
        return undefined;
      }
    } else {
      const url = new URL(myURL);
      try {
        const response = await fetch(url);
        console.log(response.status)
        if (response.status === 200 || response.status === 429) {
          const jsonResponse: IPGeoInfo = await response.json();
          console.log("jsonResponse", jsonResponse)
          return jsonResponse as IPGeoInfo;
        }
        return undefined;
      } catch {
        return undefined;
      }
    }
  }
)

export const addressinfoSlice = createSlice({
  name: 'addressinfo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAddressInfo: (state, action: PayloadAction<IPGeoInfo|undefined>) => {
      state.value = action.payload;
      console.log(state.value)
    },
  },
  extraReducers: builder => {
    builder
    .addCase(getIPInfo.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getIPInfo.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload;
    })
    .addCase(getIPInfo.rejected, (state) => {
      state.status = 'failed';
    });
  }
});

export const { setAddressInfo } = addressinfoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAddressInfo = (state: RootState) => state.addressinfo.value;
export const selectAddressInfoStatus = (state: RootState) => state.addressinfo.status;
// export const notNullCords = (state: RootState) => {
//   if (state.coordinates.value[0] != 0 && state.coordinates.value[1] != 0) {
//     return true
//   }
//   return false
// }
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default addressinfoSlice.reducer;
