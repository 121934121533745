import ThemeMode from 'Components/Main/Header/Theme';
import Lang from 'Components/Main/Header/Lang';
import Logo from 'Components/Main/Header/Logo';
import BotLink from 'Components/Main/Header/BotLink';
import styles from './index.module.scss';
import { useEffect, useState } from "react";
// import TelegramLoginButton, { TelegramUser } from '@v9v/ts-react-telegram-login';
// import TelegramLoginButton from 'react-telegram-login';
import { TLoginButton, TLoginButtonSize } from 'react-telegram-auth';
import { useTranslation } from 'i18nano';
import { env } from 'env'

export function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const translation = useTranslationChange();
  const t = useTranslation();

  // const handleTelegramResponse = (user: TelegramUser) => {
  //   console.log(user);
  // };
  const handleTelegramResponse = (response: any) => {
    console.log(response);
  };
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });



  return (
    <header className={styles.header}>
      <div className={styles.menu}>
        {/* {windowWidth >=1140 && <Logo />} */}
        <Logo />
        <div className={styles.leftMenu}>
          <BotLink />
          <Lang />
          <ThemeMode />
          {/* <div className={styles.tloginButton}>
            <TLoginButton
              // additionalClassNames='display: flex; align-self: center; align-items: center;'
              botName="webapps_test_t_t_bot"
              buttonSize={TLoginButtonSize.Medium}
              lang={t('languageCode')}
              usePic={true}
              cornerRadius={7}
              onAuthCallback={(user) => {
                console.log('Hello, user!', user); // Отправка инфы на бэк
              }}
              requestAccess={'write'}
            />
          </div> */}
          {/* <TelegramLoginButton dataOnAuth={handleTelegramResponse} botName="webapps_test_t_t_bot" /> */}
          {/* <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="webapps_test_t_t_bot" /> */}
        </div>
      </div>
    </header>
  )
}

export default Header