import Card from './Card';
import styles from './index.module.scss';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectAddressInfo, getIPInfo, setAddressInfo, selectAddressInfoStatus } from 'store/addressinfo/addressinfoSlice';
import { useEffect } from 'react';
import { clear } from 'console';
import { useTranslation } from 'i18nano';
// import { selectLang, langSet } from 'store/i18n/langSlice';

// const tg: TelegramWebApps.SDK = window.Telegram;
export const translations = {
  // Используя динамический импорт
  // при добавлении нового языка добавить в массив разрешенных в store

  'by': async () => import('i18n/be.json'),
  'de': async () => import('i18n/de.json'),
  'gb': async () => import('i18n/en.json'),
  'es': async () => import('i18n/es.json'),
  'fr': async () => import('i18n/fr.json'),
  'it': async () => import('i18n/it.json'),
  'kr': async () => import('i18n/ko.json'),
  'my': async () => import('i18n/ms.json'),
  'nl': async () => import('i18n/nl.json'),
  'pl': async () => import('i18n/pl.json'),
  'pt': async () => import('i18n/pt.json'),
  'ru': async () => import('i18n/ru.json'),
  'tr': async () => import('i18n/tr.json'),
  'ua': async () => import('i18n/uk.json'),
  'cn': async () => import('i18n/cn.json'),

};
// tg.WebApp.MainButton.isVisible =true

// tg.WebApp.MainButton.isProgressVisible = false

function WebApp() {
  const t = useTranslation();
  const addressInfo = useAppSelector(selectAddressInfo);
  const addressInfoStatus = useAppSelector(selectAddressInfoStatus);
  const dispatch = useAppDispatch();
  // const lang = useAppSelector(selectLang);


  // if (Telegram.WebApp.initData !== ""
  //  && Telegram.WebApp.initDataUnsafe.user 
  //  && Telegram.WebApp.initDataUnsafe.user.language_code) {
  //   // console.log('LANG', Telegram.WebApp.initDataUnsafe.user.language_code)
  //   if (Telegram.WebApp.initDataUnsafe.user.language_code in translations) {
  //     dispatch(langSet(Telegram.WebApp.initDataUnsafe.user.language_code));
  //   } else {
  //     dispatch(langSet('us'));
  //   }
  //   // console.log("$$$$$$$$$$$$$$$$$$$$$$")
  //   // console.log(window.location.pathname)
  //   // console.log("$$$$$$$$$$$$$$$$$$$$$$")
  //   if (window.location.pathname !== "/botip") {
  //     Telegram.WebApp.expand();
  //   }
  // }




  // console.log("11111111111111111111111111", addressInfo)
  // console.log(t('search'))
  // console.log("22222222222222222222222222", addressInfo)
  Telegram.WebApp.MainButton.setParams({text: t('search'), is_visible: false}) //text: t('search'))
  Telegram.WebApp.MainButton.isVisible = false
  // Telegram.WebApp
  useEffect(() => {

    if (addressInfo === undefined && addressInfoStatus === 'notset') {
      dispatch(getIPInfo(""));
      Telegram.WebApp.MainButton.isVisible = false
      Telegram.WebApp.MainButton.setText(t('search'))
      console.log("VVVVVVVVV", addressInfo)
    }
  },[]);

  return (
    <div className={styles.app}>
      <Card />
    </div>
  );
}

export default WebApp;
