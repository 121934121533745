import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "./loc.png";
import L from "leaflet";
import styles from './index.module.scss';
import './styles.scss';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useEffect, useState } from "react";

function Map() {
  const dispatch = useAppDispatch();
  const addressInfo = useAppSelector(state => state.addressinfo.value);


  function MapView() {

    // console.log("$$$$$$$$$",L.Control.Scale.toString)
    let map = useMap();
    // physicalScaleRatio = map._pixelsInMeterWidth * options.getMapWidthForLanInMeters(centerLat) / this._map.options.crs.scale(this._map.getZoom());
    
    if (!addressInfo || !addressInfo.Longitude || !addressInfo.Latitude) {
      map.setView([0, 0], 3)
    } else {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;


      // $extra-small: 0px;
      // $small: 600px;
      // $medium: 900px;
      // $large: 1200px;
      // $extra-large: 1536px;

      // L.control.scale({metric: true}).addTo(map);
      // let scaleCont: HTMLElement = document.getElementsByClassName("leaflet-control-scale-line")[0] as HTMLElement;
      // const onePX: number =  +(scaleCont.innerText.replace(' km', ''))/+(scaleCont.style.width.replace('px', ''));
      // map.setView([addressInfo.Latitude, addressInfo.Longitude + ((((width / 100) * (50 - 32.5)) * onePX)/6335.439)], map.getZoom())

      switch (map.getZoom()) {
        case 3:
          windowWidth <= 600 && map.setView([addressInfo.Latitude, addressInfo.Longitude], map.getZoom())
          windowWidth > 600 && windowWidth <= 900 &&   map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / 3.3)], map.getZoom())
          windowWidth > 900 && windowWidth <= 1200 &&  map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / 4.6)], map.getZoom())
          windowWidth > 1200 && windowWidth <= 1536 && map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / 5.3)], map.getZoom())
          windowWidth > 1536 &&                        map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / 5.5)], map.getZoom())
          break;
        case 6:
          windowWidth <= 600 && map.setView([addressInfo.Latitude, addressInfo.Longitude], map.getZoom())
          windowWidth > 600 && windowWidth <= 900 &&   map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (3.3*8))], map.getZoom())
          windowWidth > 900 && windowWidth <= 1200 &&  map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (3.8*10))], map.getZoom())
          windowWidth > 1200 && windowWidth <= 1536 && map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (5*10))], map.getZoom())
          windowWidth > 1536 &&                        map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (4.7*10))], map.getZoom())
          break;
        case 9:
          windowWidth <= 600 && map.setView([addressInfo.Latitude, addressInfo.Longitude], map.getZoom())
          windowWidth > 600 && windowWidth <= 900 &&   map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (3.3*65))], map.getZoom())
          windowWidth > 900 && windowWidth <= 1200 &&  map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (3.8*80))], map.getZoom())
          windowWidth > 1200 && windowWidth <= 1536 && map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (5*80))], map.getZoom())
          windowWidth > 1536 &&                        map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (4.7*80))], map.getZoom())
          break;
        default:
          windowWidth <= 600 && map.setView([addressInfo.Latitude, addressInfo.Longitude], map.getZoom())
          windowWidth > 600 && windowWidth <= 900 &&   map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (3.3*500))], map.getZoom())
          windowWidth > 900 && windowWidth <= 1200 &&  map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (3.8*600))], map.getZoom())
          windowWidth > 1200 && windowWidth <= 1536 && map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (5*600))], map.getZoom())
          windowWidth > 1536 &&                        map.setView([addressInfo.Latitude, addressInfo.Longitude + ((width / 100) * (50 - 32.5) / (4.7*600))], map.getZoom())
          break;
      }



      // L.control.scale({metric: true}).addTo(map);
      // let scaleCont: HTMLElement = document.getElementsByClassName("leaflet-control-scale-line")[0] as HTMLElement;
      // const onePX: number =  +(scaleCont.innerText.replace(' km', ''))/+(scaleCont.style.width.replace('px', ''));
      // console.log("onePX", onePX)
      // console.log("L", ((width / 100) * (50 - 32.5)) * onePX)
      // console.log("L/R", (((width / 100) * (50 - 32.5)) * onePX)/6335.439)




      // console.log("TOTAL", addressInfo.Longitude - Math.acos((Math.cos((((width / 100) * (50 - 32.5)) * onePX)/6335.439) - (Math.sin(addressInfo.Latitude)*Math.sin(addressInfo.Latitude)))/(Math.cos(addressInfo.Latitude)*Math.cos(addressInfo.Latitude)))   )
      // // map.setView([addressInfo.Latitude, addressInfo.Longitude - Math.acos((Math.cos((((width / 100) * (50 - 32.5)) * onePX)/6335.439) - (Math.sin(addressInfo.Latitude)*Math.sin(addressInfo.Latitude)))/(Math.cos(addressInfo.Latitude)*Math.cos(addressInfo.Latitude)))], map.getZoom())
      // map.setView([addressInfo.Latitude, addressInfo.Longitude], map.getZoom())


      // λB = λА - arccos((cos(L/R) - sin(φА)·sin(φB))/(cos(φА)·cos(φB)))
      // console.log("TOTAL", addressInfo.Longitude - Math.acos((Math.cos((((width / 100) * (50 - 32.5)) * onePX)/6335.439) - Math.sin(addressInfo.Latitude)*Math.sin(addressInfo.Latitude))/((Math.cos(addressInfo.Latitude)*Math.cos(addressInfo.Latitude)))))
      // map.setView([addressInfo.Latitude, addressInfo.Longitude + Math.acos((Math.cos((((width / 100) * (50 - 32.5)) * onePX)/6335.439) - Math.sin(addressInfo.Latitude)*Math.sin(addressInfo.Latitude))/(Math.cos(addressInfo.Latitude)*Math.cos(addressInfo.Latitude)))], map.getZoom())


      // switch (map.getZoom()) {
      //   case 3:
      //     map.setView([addressInfo.Latitude, addressInfo.Longitude + (((width / 100 * 50) - (width / 100 * 32.5))/ 1000)], map.getZoom())
      //     L.control.scale({metric: true}).addTo(map);

      //     break;
      //   case 7:
      //     map.setView([addressInfo.Latitude, addressInfo.Longitude + (((width / 100 * 50) - (width / 100 * 32.5))/ 300)], map.getZoom())
      //     break;
      //   default:
      //     map.setView([addressInfo.Latitude, addressInfo.Longitude + (((width / 100 * 50) - (width / 100 * 32.5))/ 1600)], map.getZoom())
      //     break;
      // }

      // map.setView([addressInfo.Latitude, addressInfo.Longitude]);// + ((((width / 100) * (50 - 32.5))*onePX)], map.getZoom())
      // L.control.scale({metric: true}).addTo(map);
      // let scaleCont: HTMLElement = document.getElementsByClassName("leaflet-control-scale-line")[0] as HTMLElement;
      // console.log('PX: ', +scaleCont.style.width.replace('px', ''));
      // console.log('KM: ', +(scaleCont.innerText.replace(' km', '')));
      // console.log('width: ', width);
      // const onePX: number =  +(scaleCont.innerText.replace(' km', ''))/+(scaleCont.style.width.replace('px', ''));
      // console.log('onePX: ',onePX);
      // console.log('OLD: ',addressInfo.Longitude);
      // console.log('PIXELS: ',((width / 100) * (50 - 25)));
      // console.log('KILOMITERS: ',((width / 100) * (50 - 32.5))*onePX);
      // // console.log('TOTAL: ',(((width / 100) * (50 - 32.5)) * onePX);
      // console.log('TOTAL2: ',2*Math.asin(Math.sqrt(Math.pow(Math.sin((((width / 100) * (50 - 25)) * onePX)/2*6335.439), 2)/Math.cos(addressInfo.Latitude)*Math.cos(addressInfo.Latitude)))-addressInfo.Longitude);
      // // map.setView([addressInfo.Latitude, 2*Math.asin(Math.sqrt(Math.pow(Math.sin((((width / 100) * (50 - 25)) * onePX)/2*6335.439), 2)/(Math.cos(addressInfo.Latitude)*Math.cos(addressInfo.Latitude))))+addressInfo.Longitude], map.getZoom())
      // map.setView([addressInfo.Latitude, addressInfo.Longitude], map.getZoom())


      // 2*Math.asin(Math.sqrt(Math.pow(Math.sin((((width / 100) * (50 - 32.5)) * onePX)/2*6335.439), 2)/Math.cos(addressInfo.Latitude)*Math.cos(addressInfo.Latitude)))+addressInfo.Longitude


// d/r+dolg1=x


      // DISTANCE = R * 2 * Math.atan2(Math.sqrt((Math.sin(((lat2-lat1) * (Math.PI/180))/2) * Math.sin(((lat2-lat1) * (Math.PI/180))/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(((lon2-lon1) * (Math.PI/180))/2) * Math.sin(((lon2-lon1) * (Math.PI/180))/2))), Math.sqrt(1-(Math.sin(((lat2-lat1) * (Math.PI/180))/2) * Math.sin(((lat2-lat1) * (Math.PI/180))/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(((lon2-lon1) * (Math.PI/180))/2) * Math.sin(((lon2-lon1) * (Math.PI/180))/2))));


      // function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
      //   var R = 6371; // Radius of the earth in km
      //   var dLat = deg2rad(lat2-lat1);  // deg2rad below
      //   var dLon = deg2rad(lon2-lon1); 
      //   var a = 
      //     Math.sin(dLat/2) * Math.sin(dLat/2) +
      //     Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      //     Math.sin(dLon/2) * Math.sin(dLon/2)
      //     ; 
      //   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      //   var d = R * c; // Distance in km
      //   return d;
      // }
      
      // function deg2rad(deg) {
      //   return deg * (Math.PI/180)
      // }
      
      // const distanceMOWBKK = getDistanceFromLatLonInKm(
      //   55.45, 37.36, 13.45, 100.30
      // )
      
      // console.log(distanceMOWBKK);









      // map.setView([addressInfo.Latitude, addressInfo.Longitude], 11)
      // windowWidth <= 700 && map.setView([addressInfo.Latitude, addressInfo.Longitude], map.getZoom())
      // windowWidth > 700 && windowWidth <= 1140 && map.setView([addressInfo.Latitude, addressInfo.Longitude + (width / 100 * 25 / 1100)], map.getZoom())
      // windowWidth > 1140 && windowWidth <= 1420 && map.setView([addressInfo.Latitude, addressInfo.Longitude + (width / 100 * 32 / 3000)], map.getZoom())
      // windowWidth > 1420 && map.setView([addressInfo.Latitude, addressInfo.Longitude + (width / 100 * 25 / 2000)], map.getZoom())
      if (width < 600 ) {
        console.log("11111")
        // setTimeout(() => {
          console.log("22222")
          let btm = (document.getElementsByClassName("leaflet-bottom leaflet-right") as HTMLCollectionOf<HTMLElement>)[0];
          btm.style.display = 'none';
          if (btm) {
            console.log("33333")
            btm.style.display = 'none';
          }
        // }, 1);
      } else {
        let btm = (document.getElementsByClassName("leaflet-bottom leaflet-right") as HTMLCollectionOf<HTMLElement>)[0];
        if (btm) {
          btm.style.display = 'block';
        }
      }
    }
    return (<></>);
  }

  const customIcon = new L.Icon({
    iconUrl: icon,
    iconSize: [50, 50],
    iconAnchor: [5, 30]
  });

  function Cleaner() {
    setTimeout(() => {
      let btm = document.getElementsByClassName("leaflet-control-attribution leaflet-control")[0];
      let link = btm.getElementsByTagName("a")[0];
      link.innerHTML = '';
      link.text = "Leaflet";
    }, 1);
    return (<></>)
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };
  
  //   window.addEventListener('resize', handleWindowResize);
    
    
  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // },[]);

  return (
    <div className={styles.map}>
      <MapContainer
        center={addressInfo && addressInfo.Latitude && addressInfo.Longitude ? [addressInfo.Latitude, addressInfo.Longitude] : [0,0]}
        // zoom={3}
        zoomControl={false}
        scrollWheelZoom={true}
        minZoom={3}
        maxZoom={12}
        zoomDelta={3}
        zoomSnap={3}
        // layers={[3,7,11]}
      >

{/* https://stackoverflow.com/questions/65337803/react-leaflet-v3-zoom-listener */}
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {addressInfo && addressInfo.Latitude && addressInfo.Longitude ? <Marker icon={customIcon} position={[addressInfo.Latitude, addressInfo.Longitude]} /> : <></>}
        <MapView />
        <Cleaner />
      </MapContainer>
    </div>
  );
}

export default Map