import { ILocation } from "api/types";
import config from "../../api/config";

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export interface State {
  value:  Array<ILocation>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: State = {
  value: [],
  status: 'idle',
};

export const getCities = createAsyncThunk(
  'cities/getPosts',
  async (userInput: string) => {
    const url = new URL("https://api.openweathermap.org/geo/1.0/direct");
    url.search = new URLSearchParams({
      limit: "15",
      q: userInput,
      appid: config.API_KEY,
    }).toString();
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const cities = await response.json();
        return cities;
      }
      return [];
    } catch {
      return [];
    }
  }
)

export const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCities: (state, action: PayloadAction<Array<ILocation>>) => {
      state.value = action.payload;
    },
  },
  extraReducers: builder => {
    builder
    .addCase(getCities.pending, (state) => {
      state.status = 'loading';
      console.log('loading')
    })
    .addCase(getCities.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload;
    })
    .addCase(getCities.rejected, (state) => {
      console.log('failed')
      state.status = 'failed';
    });
  }
});

export const { setCities } = citiesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCities = (state: RootState) => state.cities.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default citiesSlice.reducer;
