interface AppConfig {
  API_KEY: string; // Ключ от API
  GEO_API_KEY: string;
  REQUEST_TIMEOUT: number; // Задержка перед отправкой запроса к API
}

/**
 * Конфигурация приложения
 */
const config: AppConfig = {
  API_KEY: process.env.REACT_APP_API_KEY || "65c09233f09ee168d6d5d59f7e254364",
  GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY || "32767fbaca4df3",
  REQUEST_TIMEOUT: 1000,
};

export default config;
