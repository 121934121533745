function BoolIcon() {
  return (
    <svg height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 0H12C12.5304 0 13.0391 0.210714 13.4142 0.585786C13.7893 0.960859 14 1.46957 14 2V12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H2C1.46957 14 0.960859 13.7893 0.585786 13.4142C0.210714 13.0391 0 12.5304 0 12V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0V0Z" fill="#B373FE" />
      <path d="M2.40084 6.80798C2.3956 6.47418 2.43529 6.14119 2.51884 5.81798C2.58387 5.56561 2.69656 5.32801 2.85084 5.11798C2.98495 4.93881 3.16068 4.795 3.36284 4.69898C3.57107 4.60371 3.79787 4.55589 4.02684 4.55898C4.25626 4.5473 4.48511 4.5911 4.69401 4.68667C4.9029 4.78223 5.08567 4.92675 5.22684 5.10798C5.54615 5.61474 5.6941 6.21072 5.64884 6.80798C5.65467 7.1451 5.61498 7.48147 5.53084 7.80798C5.46595 8.06285 5.35329 8.3031 5.19884 8.51598C5.06479 8.69714 4.88782 8.84215 4.68384 8.93798C4.47448 9.03305 4.24676 9.08084 4.01684 9.07798C3.78425 9.08957 3.55247 9.04253 3.34279 8.94118C3.13311 8.83984 2.95226 8.68744 2.81684 8.49798C2.50683 7.99118 2.3615 7.40077 2.40084 6.80798ZM4.85884 6.80798V6.61898C4.85884 6.55964 4.85451 6.49964 4.84584 6.43898L3.32584 7.82598C3.35985 7.98624 3.44801 8.1299 3.57549 8.2328C3.70298 8.3357 3.86201 8.39156 4.02584 8.39098C4.15351 8.39213 4.2789 8.35716 4.38754 8.29009C4.49618 8.22303 4.58365 8.12662 4.63984 8.01198C4.8105 7.63469 4.88571 7.2212 4.85884 6.80798ZM3.19584 6.80798V6.98498C3.19584 7.04098 3.19884 7.09798 3.20484 7.15598L4.72184 5.77198C4.68391 5.61629 4.59371 5.47834 4.4663 5.38115C4.3389 5.28396 4.18202 5.23342 4.02184 5.23798C3.89416 5.2367 3.76878 5.272 3.66051 5.33969C3.55225 5.40738 3.46561 5.50463 3.41084 5.61998C3.24374 5.99266 3.16962 6.40033 3.19484 6.80798H3.19584ZM7.80684 4.56698L8.41484 4.83898L6.19884 9.85498L5.59084 9.56998L7.80684 4.56698ZM8.78084 8.30398H9.73084V5.59198L8.91184 6.21898L8.53984 5.71898L9.95384 4.63898H10.4998V8.30398H11.4308V8.98698H8.78084V8.30398Z" fill="white"/>
    </svg>
  );
}

export default BoolIcon;

