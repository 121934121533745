import styles from './index.module.scss'
import { selectTheme } from 'store/theme/themeSlice';
import { useAppSelector } from 'store/hooks';

export function Logo() {
  const theme = useAppSelector(selectTheme);

  return (

  <a className={styles.logoContainer} href='/'>
      <svg className={styles.logo} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path style={{opacity: 0.979}} fill="#c74343" d="M 248.5,11.5 C 320.242,11.6052 374.742,41.9385 412,102.5C 437.953,148.894 441.62,196.894 423,246.5C 408.449,281.608 390.782,314.941 370,346.5C 334.754,399.645 296.588,450.645 255.5,499.5C 205.86,441.883 161.36,380.55 122,315.5C 108.957,293.389 97.6237,270.389 88,246.5C 64.3237,174.038 79.8237,111.205 134.5,58C 167.178,29.4957 205.178,13.9957 248.5,11.5 Z"/>
          <path style={{opacity: 1}} fill="#f68e8e" d="M 235.5,25.5 C 307.998,20.9942 363.164,49.3276 401,110.5C 426.001,156.189 428.001,202.855 407,250.5C 387.981,291.554 365.648,330.554 340,367.5C 313.289,405.578 285.123,442.578 255.5,478.5C 209.339,423.847 167.839,365.847 131,304.5C 116.02,279.538 104.02,253.205 95,225.5C 81.395,160.648 99.5617,106.482 149.5,63C 174.728,42.5512 203.395,30.0512 235.5,25.5 Z"/>
          <path style={{opacity: 1}} fill="#fbcfcf" d="M 183.5,147.5 C 183.5,191.833 183.5,236.167 183.5,280.5C 193.167,280.5 202.833,280.5 212.5,280.5C 212.5,236.167 212.5,191.833 212.5,147.5C 213.499,191.997 213.832,236.664 213.5,281.5C 203.167,281.5 192.833,281.5 182.5,281.5C 182.168,236.664 182.501,191.997 183.5,147.5 Z"/>
          <path style={{opacity: 1}} fill="#fefffe" d="M 183.5,147.5 C 193.167,147.5 202.833,147.5 212.5,147.5C 212.5,191.833 212.5,236.167 212.5,280.5C 202.833,280.5 193.167,280.5 183.5,280.5C 183.5,236.167 183.5,191.833 183.5,147.5 Z"/>
          <path style={{opacity: 1}} fill="#fefefe" d="M 247.5,147.5 C 266.17,147.333 284.836,147.5 303.5,148C 335.762,150.937 349.262,168.437 344,200.5C 338.9,218.596 327.067,229.43 308.5,233C 298.287,235.177 287.954,236.011 277.5,235.5C 277.5,250.833 277.5,266.167 277.5,281.5C 267.5,281.5 257.5,281.5 247.5,281.5C 247.5,236.833 247.5,192.167 247.5,147.5 Z"/>
          <path style={{opacity: 1}} fill="#f79090" d="M 277.5,170.5 C 283.509,170.334 289.509,170.501 295.5,171C 306.88,172.547 312.88,179.047 313.5,190.5C 313.724,202.889 307.724,210.056 295.5,212C 289.509,212.499 283.509,212.666 277.5,212.5C 277.5,198.5 277.5,184.5 277.5,170.5 Z"/>
      </svg>
      <div className={styles.logoName}>
        IPinfo.bot
      </div>
  </a>

    
  )
}

export default Logo