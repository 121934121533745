function ArrayIcon() {
  return (
    <svg height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0H2C0.89543 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.89543 13.1046 0 12 0Z" fill="#5184F7" />
      <path d="M4.65498 10.104H3.00098V3.99701H4.65498V4.71801H3.88898V9.38301H4.65498V10.104ZM7.80298 8.99701L7.60298 8.47701H7.57398C7.43166 8.67051 7.24605 8.82804 7.03198 8.93701C6.8018 9.03071 6.55432 9.07435 6.30598 9.06501C6.14791 9.07376 5.98972 9.05046 5.84089 8.99649C5.69207 8.94253 5.5557 8.85903 5.43998 8.75101C5.32897 8.6303 5.24371 8.48825 5.18938 8.33352C5.13506 8.17879 5.1128 8.01462 5.12398 7.85101C5.11099 7.67695 5.14341 7.5025 5.21809 7.34474C5.29276 7.18697 5.40712 7.0513 5.54998 6.95101C5.94054 6.72953 6.38415 6.61889 6.83298 6.63101L7.49598 6.61001V6.44301C7.50639 6.36301 7.49804 6.28169 7.47159 6.20547C7.44515 6.12925 7.40133 6.06024 7.34362 6.00387C7.2859 5.94751 7.21585 5.90535 7.13903 5.88073C7.0622 5.85611 6.98071 5.8497 6.90098 5.86201C6.52669 5.8789 6.15997 5.97322 5.82398 6.13901L5.47898 5.43901C5.93132 5.20704 6.43367 5.08961 6.94198 5.09701C7.36187 5.06917 7.77846 5.18754 8.12098 5.43201C8.26312 5.55886 8.37406 5.71681 8.44517 5.89355C8.51629 6.07029 8.54565 6.26106 8.53098 6.45101V8.99701H7.80298ZM7.49498 7.22601L7.09498 7.24001C6.85855 7.23166 6.62437 7.28839 6.41798 7.40401C6.34447 7.45458 6.28536 7.52338 6.24644 7.60367C6.20753 7.68396 6.19014 7.77298 6.19598 7.86201C6.19598 8.15601 6.36464 8.30301 6.70198 8.30301C6.91369 8.31336 7.12085 8.23927 7.27798 8.09701C7.35092 8.025 7.40792 7.93848 7.4453 7.84305C7.48268 7.74762 7.49961 7.6454 7.49498 7.54301V7.22601ZM9.23198 9.38301H9.99398V4.72101H9.23198V3.99701H10.886V10.097H9.23198V9.38301Z" fill="white" />
    </svg>
  );
}

export default ArrayIcon;

