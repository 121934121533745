import styles from './index.module.scss'
import WeatherCard from 'Components/Main/WeatherCard';
import Input from './Input';
import Info from './Info';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectWeatherArray } from 'store/weatherarray/weatherarraySlice';
import { selectUserInput } from 'store/userinput/userinputSlice';
import { getWeather } from 'store/weatherarray/weatherarraySlice';
import { selectCities } from 'store/cities/citiesSlice';
// import { selectCoordinates } from 'store/coordinates/coordinatesSlice';
import { getCityByPosition, selectCity } from 'store/city/citySlice';
import { useEffect } from "react";
import { selectAddressInfo, getIPInfo, setAddressInfo } from 'store/addressinfo/addressinfoSlice';

export function Card() {

  const addressInfo = useAppSelector(selectAddressInfo);

  return (
    <main className={styles.main}>
      <Input />
      {addressInfo !== undefined && <Info />}
      {/* {addressInfo !== undefined ? <Info /> : <Loading />} */}
    </main>
  )
}

export default Card