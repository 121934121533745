import { IDailyForecast, ILocation } from "../../api/types";
import config from "../../api/config";

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface State {
  value: IDailyForecast;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: State = {
  value: {list: [], city: {sunrise:0, sunset:0, timezone:0, id:0, name:'', population:0, country:'', coord: {lat:0,lon:0}}},
  status: 'idle',
};

export const getWeather = createAsyncThunk(
  'weather/getPosts',
  async (location: Array<number>) => {
    const url = new URL("https://api.openweathermap.org/data/2.5/forecast");
    url.search = new URLSearchParams({
      units: "metric",
      // cnt: "8",
      lang: "ru",
      lat: location[0].toString(),
      lon: location[1].toString(),
      appid: config.API_KEY,
    }).toString();
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const weather = await response.json();
        return weather;
      }
      return {list: [], city: {sunrise:0, sunset:0, timezone:0}};
    } catch {
      return {list: [], city: {sunrise:0, sunset:0, timezone:0}};
    }
  }
)

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWeather: (state, action: PayloadAction<IDailyForecast>) => {
      state.value = action.payload;
    },
  },
  extraReducers: builder => {
    builder
    .addCase(getWeather.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getWeather.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload;
    })
    .addCase(getWeather.rejected, (state) => {
      state.status = 'failed';
    });
  }
});

export const { setWeather } = weatherSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectWeather = (state: RootState) => state.weather.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default weatherSlice.reducer;
