function StringIcon() {
  return (
    <svg height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0H2C0.89543 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.89543 13.1046 0 12 0Z" fill="#409EE8" />
      <path d="M4.264 8.58296C4.264 7.99363 3.84267 7.69896 3 7.69896V6.79096C3.33203 6.81474 3.66366 6.73957 3.953 6.57496C4.05664 6.5009 4.13962 6.40157 4.19405 6.2864C4.24849 6.17123 4.27257 6.04406 4.264 5.91696V4.43996C4.24859 4.22919 4.28802 4.01802 4.37844 3.827C4.46887 3.63599 4.60721 3.47164 4.78 3.34996C5.25152 3.08476 5.79027 2.9631 6.33 2.99996V3.86496C6.05149 3.85587 5.77696 3.93306 5.544 4.08596C5.46027 4.15797 5.39451 4.24853 5.35198 4.35045C5.30944 4.45236 5.2913 4.5628 5.299 4.67296V6.08396C5.299 6.71463 4.93633 7.09163 4.211 7.21496V7.27196C4.93633 7.38596 5.299 7.7613 5.299 8.39796V9.81796C5.2914 9.92813 5.30927 10.0386 5.35124 10.1407C5.39321 10.2429 5.45814 10.334 5.541 10.407C5.77408 10.561 6.05123 10.6344 6.33 10.616V11.486C5.77757 11.5263 5.22608 11.3961 4.75 11.113C4.5811 10.9701 4.44911 10.7886 4.36521 10.5839C4.28131 10.3792 4.24796 10.1573 4.268 9.93696L4.264 8.58296ZM9.523 10.042C9.53964 10.2482 9.50519 10.4554 9.42269 10.6452C9.34018 10.8349 9.21218 11.0014 9.05 11.13C8.59296 11.3981 8.06566 11.5222 7.537 11.486V10.616C7.78343 10.6268 8.02739 10.563 8.237 10.433C8.32668 10.3594 8.39666 10.2646 8.44064 10.1573C8.48463 10.0499 8.50125 9.93333 8.489 9.81796V8.57296C8.46892 8.25654 8.55786 7.94278 8.741 7.68396C8.95964 7.4485 9.25714 7.30153 9.577 7.27096V7.21396C8.85167 7.09063 8.489 6.71363 8.489 6.08296V4.67196C8.49703 4.56437 8.48191 4.4563 8.44465 4.35504C8.40739 4.25379 8.34886 4.16169 8.273 4.08496C8.05855 3.93024 7.79818 3.85237 7.534 3.86396V2.99896C8.06748 2.95327 8.60149 3.08055 9.057 3.36196C9.2216 3.51049 9.34919 3.69545 9.42958 3.90207C9.50997 4.10869 9.54094 4.33125 9.52 4.55196V5.91596C9.50731 6.04482 9.52828 6.17476 9.58087 6.29308C9.63345 6.4114 9.71585 6.51404 9.82 6.59096C10.0946 6.74258 10.407 6.81201 10.72 6.79096V7.69896C10.41 7.67898 10.1007 7.7484 9.829 7.89896C9.72319 7.9763 9.63933 8.07986 9.58567 8.19944C9.53202 8.31902 9.51042 8.4505 9.523 8.58096V10.042Z" fill="white" />
    </svg>
  );
}

export default StringIcon;

