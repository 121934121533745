import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';

export interface State {
  value: boolean;
}
const initialState = createInitialState();

function createInitialState():State {
  //Get from local storage
  const localFiveDays = `${window?.localStorage?.getItem('fiveDays')}`
  if (localFiveDays) {
    return {value: (localFiveDays === 'true')}
  } else {
    return {value: false}
  }
}
export const fiveDaysSlice = createSlice({
  name: 'fiveDays',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fiveDaysChange: (state) => {
      state.value = !state.value
    },
  },
});

export const { fiveDaysChange } = fiveDaysSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFiveDays = (state: RootState) => state.fiveDays.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default fiveDaysSlice.reducer;
