// import React from 'react';
import React, { useEffect } from 'react'

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { useTranslationChange } from 'i18nano';
import { selectLang, langSet } from 'store/i18n/langSlice';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import WebApp from './Components/WebApp';
// import { Translations } from 'types/index';

const container = document.getElementById('root')!;
const root = createRoot(container);

// const tg: TelegramWebApps.SDK = window.Telegram;
Telegram.WebApp.onEvent('themeChanged', function(){
  document.documentElement.dataset.theme = Telegram.WebApp.colorScheme as string
  localStorage.setItem('theme', Telegram.WebApp.colorScheme as string)
});

if (Telegram.WebApp.initData !== "") {
  document.documentElement.dataset.theme = Telegram.WebApp.colorScheme as string
  document.documentElement.dataset.tg = "true";
  // if (Telegram.WebApp.initDataUnsafe.user && Telegram.WebApp.initDataUnsafe.user.language_code) {
  //   console.log("language_code", Telegram.WebApp.initDataUnsafe.user.language_code)
  //   dispatch(langSet(Telegram.WebApp.initDataUnsafe.user.language_code))
  // } 
}




root.render(
  <React.StrictMode>
    <Provider store={store}>
        {/* { Telegram.WebApp.initData === "" ? 
        <App />:
        <WebApp />
        } */}
        {/* { Telegram.WebApp.initData === "" ?  */}
        {/* <App />: */}
        <App />
        {/* <WebApp /> */}
        {/* } */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
