import { IDailyForecast, ILocation, IDailyForecastArray, IDailyWeather } from "api/types";
import config from "api/config";
import moment from 'moment';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { Console } from "console";

export interface State {
  value: IDailyForecastArray;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: State = {
  value: {list: [], city: {sunrise:0, sunset:0, timezone:0, id:0, name:'', population:0, country:'', coord: {lat:0,lon:0}}},
  status: 'idle',
};
// https://api.openweathermap.org/data/2.5/forecast?units=metric&cnt=10&lang=ru&lat=51.24840638990796&lon=-85.03524867109424&appid=65c09233f09ee168d6d5d59f7e254364

export const getWeather = createAsyncThunk(
  'weatherArray/getPosts',
  async (location: Array<number>) => {
    const url = new URL("https://api.openweathermap.org/data/2.5/forecast");
    url.search = new URLSearchParams({
      units: "metric",
      // cnt: "8",
      lang: "ru",
      lat: location[0].toString(),
      lon: location[1].toString(),
      appid: config.API_KEY,
    }).toString();
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const weather = await response.json();
        return weather;
      }
      return {list: [], city: {sunrise:0, sunset:0, timezone:0}};
    } catch {
      return {list: [], city: {sunrise:0, sunset:0, timezone:0}};
    }
  }
)

export const weatherArraySlice = createSlice({
  name: 'weatherArray',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWeatherArray: (state, action: PayloadAction<IDailyForecastArray>) => {
      state.value = action.payload;
    },
  },
  extraReducers: builder => {
    builder
    .addCase(getWeather.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getWeather.fulfilled, (state, action) => {
      state.status = 'idle';
      let days: IDailyForecastArray = {city: action.payload.city, list: []}
      let fDays: Map<number, Array<IDailyWeather>> = new Map();
      const first: number = moment.unix(action.payload.list[0].dt).date()
      action.payload.list.map((wea: IDailyWeather) => {

        let check = fDays.get(moment.unix(wea.dt).date())
        if (check) {
          check.push(wea)
        } else {
          check = [wea]
        }
        fDays.set(moment.unix(wea.dt).date(), check)
      })
      let dd: Array<{day: number, weather: IDailyWeather[], show: boolean}> = [];

      fDays.forEach((value, key)=>{
        key === first ? dd.push({day: key, weather: value, show: true}) : dd.push({day: key, weather: value, show: false})
      })
      days.list = dd
      state.value = days
    })
    .addCase(getWeather.rejected, (state) => {
      state.status = 'failed';
    });
  }
});

export const { setWeatherArray } = weatherArraySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectWeatherArray = (state: RootState) => state.weatherArray.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default weatherArraySlice.reducer;
