import styles from './index.module.scss';
import { useTranslationChange } from 'i18nano';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectLang, langSet } from 'store/i18n/langSlice';
import { useState } from 'react';
import Flag from 'react-flagkit';

function LanguageChange() {
  const translation = useTranslationChange();
  const lang = useAppSelector(selectLang);
  const dispatch = useAppDispatch();
  // var showFlags: boolean = false;
  const [showFlags, setShowFlags] = useState(false);

  const langChange = (l: string) => {
    translation.preload(l);
    translation.change(l);
    dispatch(langSet(l));
    setShowFlags(false);
  };

  const showLangs = () => {
    console.log("showFlags", showFlags)
    if (showFlags) {
      setShowFlags(false);
    } else {
      setShowFlags(true);
    }
    // showFlags = true;
  }

  return (
    <div className={styles.flagContainer}>
      <Flag country={lang.toUpperCase()} className={styles.Flag} size={10} onClick={showLangs}/>
      <div className={styles.select_flag}>
        {showFlags && translation.all.map((l) => (
          l !== translation.lang &&
          <div
            key={l}
            className={styles.select}
            onClick={() => langChange(l)}
          >
            <Flag country={l.toUpperCase()} className={styles.Flag}/>
          </div>
        ))}
      </div>
    </div>
  )


// {cities.map((city, index) => (
//   <div
//     key={index}
//     className={styles.select}
//     onClick={(event) => handleInputChoice(event, index)}
//   >
//     {city.name} - {city.state},{city.country}
//   </div>
// ))}




  //   <select value={translation.lang} onChange={langChange}>
  //     <Flag country={lang}  className={styles.Flag} />
  //     {translation.all.map((lang) => (
  //       <option key={lang} value={lang}>
  //         <Flag country={lang}  className={styles.Flag} />
  //         {/* {lang} */}
  //       </option>
  //     ))}
  //   </select>
  // );
};

export default LanguageChange
