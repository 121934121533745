import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import themeReducer from './theme/themeSlice';
import addressinfoReducer from './addressinfo/addressinfoSlice';
import userinputReducer from './userinput/userinputSlice';
import citiesReducer from './cities/citiesSlice';
import weatherReducer from './weather/weatherSlice';
import weatherArrayReducer from './weatherarray/weatherarraySlice';
import cityReducer from './city/citySlice';
import fiveDaysReducer from './fiveDays/fiveDaysSlice';
// import telegramReducer from './telegram/telegramSlice';
import langReducer from './i18n/langSlice';

// import themeReducer from '../features/theme/themeSlice';

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    lang: langReducer,
    addressinfo: addressinfoReducer,
    userinput: userinputReducer,
    cities: citiesReducer,
    weather: weatherReducer,
    weatherArray: weatherArrayReducer,
    city: cityReducer,
    fiveDays: fiveDaysReducer,
    // telegram: telegramReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
