import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface State {
  value: string;
}


const languages = ['gb', 'us', 'ru', 'br', 'ca', 'ml', 'ua', 'za','ru','nl','fr','en','de','it','kr','my','pl','pt','es','tr','uk', 'cn', 'by']

const initialState = createInitialState();

function createInitialState():State {
  //Get from local storage
  const languageWasSet = `${window?.localStorage?.getItem('language')}`;
  // console.log('languageWasSet', languageWasSet);
  if (languageWasSet !== "" && languageWasSet !== "null" && languageWasSet !== null && languages.includes(languageWasSet)) return { value: languageWasSet}
  const lang = navigator.languages.find((lang) => lang.includes('-'));
  if (lang) {
    const languageFromBrowser = lang.slice(3, 5).toLowerCase();
    window?.localStorage?.setItem('language', languageFromBrowser);
    // console.log('languageFromBrowser', languageFromBrowser);
    return { value: languageFromBrowser}

  }
  window?.localStorage?.setItem('language', 'us');
  return { value: 'us' }
}
export const langSlice = createSlice({
  name: 'lang',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    langSet: (state, action: PayloadAction<string>) => {
      // console.log("3 Поверка на наличие языка: ",action.payload)
      if (languages.includes(action.payload)) {
        // console.log("4 Сохранение в вэлью: ",action.payload)
        state.value = action.payload;
        // console.log("5 Сохранение в локалстореж: ",languages.includes(action.payload))
        window?.localStorage?.setItem('language', action.payload);
      }
    },
  },
});

export const { langSet } = langSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLang = (state: RootState) => state.lang.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default langSlice.reducer;
